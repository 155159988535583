import { render, staticRenderFns } from "./nuevoTransportista.vue?vue&type=template&id=22f1b8d8&scoped=true&"
import script from "./nuevoTransportista.vue?vue&type=script&lang=js&"
export * from "./nuevoTransportista.vue?vue&type=script&lang=js&"
import style1 from "./nuevoTransportista.vue?vue&type=style&index=1&id=22f1b8d8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22f1b8d8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('22f1b8d8')) {
      api.createRecord('22f1b8d8', component.options)
    } else {
      api.reload('22f1b8d8', component.options)
    }
    module.hot.accept("./nuevoTransportista.vue?vue&type=template&id=22f1b8d8&scoped=true&", function () {
      api.rerender('22f1b8d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/centros/modales/nuevoTransportista.vue"
export default component.exports