<template>
  <div>
    <maca-modal
      titulo="Beneficiarios Asignados"
      :impedirClose="impedirClose"
      ref="modal"
      :mensajeConfirmacion="false"
    >
      <el-card :body-style="{ padding: '1.5%' }" style="margin: 2%">
        <div v-show="divCalendario == 0">
          <div slot="header">
            <div style="margin-left: 0px">
              <el-input
                class="buscador tamañoInputBucar"
                prefix-icon="el-icon-search"
                placeholder="Presione ENTER para buscar"
                v-model="filtroNombre"
              >
              </el-input>
              <!-- <el-button
                class="botonmasIcono"
                icon="el-icon-refresh"
                type="primary"
                circle
                style="margin-left: 0.2rem"
                @click="recargarTabla()"
              ></el-button> -->
            </div>

            <el-button
              class="botonmasIcono ocultarMostrarItem"
              icon="el-icon-plus"
              type="primary"
              circle
              style="margin-left: 0.2rem"
              @click="$refs.modalNuevo.abrir()"
            ></el-button>
          </div>

          <div>
            <maca-datatable
              :url="urlTabla"
              :params="paramsTabla"
              :actualizar.sync="actualizarTabla"
              :bloquear.sync="bloquearTabla"
            >
              <el-table-column label="Apellido">
                <template slot-scope="props">
                  <span
                    v-text="
                      props.row.detalleBeneficiario != null
                        ? props.row.detalleBeneficiario.detallePersona.apellido
                        : ''
                    "
                  >
                  </span>
                </template>
              </el-table-column>

              <el-table-column label="Nombre">
                <template slot-scope="props">
                  <span
                    v-text="
                      props.row.detalleBeneficiario != null
                        ? props.row.detalleBeneficiario.detallePersona.nombre
                        : ''
                    "
                  >
                  </span>
                </template>
              </el-table-column>

              <el-table-column label="Razon Social">
                <template slot-scope="props">
                  <span
                    v-text="
                      props.row.detalleBeneficiario != null
                        ? props.row.detalleBeneficiario.detallePersona
                            .razonSocial
                        : ''
                    "
                  >
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="cuitCuil" label="CUIT/CUIL">
              </el-table-column>

              <el-table-column prop="telefono" label="Teléfono">
              </el-table-column>
              <el-table-column label="Fallecido">
                <template slot-scope="props">
                  <div v-if="props.row.fallecido == 0">
                    <span>SI </span>
                  </div>
                  <div v-else>
                    <span>NO </span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column width="70">
                <template slot-scope="props">
                  <el-popover
                    placement="top-start"
                    title="Horarios"
                    width="200"
                    trigger="hover"
                    content="Días y horarios del beneficiario en el centro"
                  >
                    <el-button
                      slot="reference"
                      type="Info"
                      circle
                      @click="irCalendario(props.row)"
                      :disabled="!$store.getters.tienePermiso('M_USE')"
                    >
                      <i class="el-icon-date"></i>
                    </el-button>
                  </el-popover>
                </template>
              </el-table-column>

              <el-table-column width="70">
                <template slot-scope="props">
                  <el-button
                    type="danger"
                    @click="eliminarTabla(props.row.id)"
                    circle
                    :disabled="!$store.getters.tienePermiso('B_USE')"
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </template>
              </el-table-column>
            </maca-datatable>
          </div>
        </div>
        <div v-show="divCalendario == 1">
          <el-form
            label-position="left"
            :model="form"
            ref="form"
            label-width="75px"
            :rules="formRules"
          >
            <div style="margin-bottom: 20px">
              <el-row>
                <el-col :span="24" :offset="0">
                  <h2 style="color: darkgray; font-weight: bold">
                    {{
                      form.tipo != null && form.tipo.nombre != null
                        ? form.tipo.nombre
                        : ""
                    }}
                  </h2></el-col
                >
              </el-row>
              <span style="font-weight: bold">
                Ingresar dia y horarios de ingreso y egreso de sesión:
              </span>

              <el-row
                :gutter="25"
                style="margin-top: 35px; margin-bottom: 20px"
              >
                <el-col :span="6">
                  <el-form-item label="Día:">
                    <el-select
                      v-model="form.dia"
                      multiple=""
                      placeholder="Elegir Día"
                    >
                      <el-option
                        v-for="item in arrayDias"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- PICKER DIALISIS -->
                <el-col
                  :span="10"
                  v-if="form.tipo != null && form.tipo.codigo == 'DIALISIS'"
                >
                  <el-form-item label="Horario:">
                    <el-time-picker
                      v-model="form.horario"
                      placeholder="Hora Ingreso"
                      format="HH:mm"
                      value-format="HH:mm"
                      style="width: 100%"
                    >
                    </el-time-picker>
                  </el-form-item>
                </el-col>
                <!-- PICKER OTROS -->
                <el-col :span="10" v-else>
                  <el-form-item label="Horario:">
                    <el-time-picker
                      is-range
                      v-model="form.horario"
                      range-separator="A"
                      selectableRange="array"
                      start-placeholder="Hora Ingreso"
                      end-placeholder="Hora Egreso"
                      format="HH:mm"
                      value-format="HH:mm"
                    >
                    </el-time-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item label="">
                    <div style="margin-right: 0px; margin-top: 2px">
                      <el-button
                        type="primary"
                        size="small"
                        @click="agregarTabla()"
                      >
                        <i class="el-icon-plus"></i>
                      </el-button>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>

            <div>
              <el-table
                v-loading="horariosLoading"
                ref="tabla"
                :data="datosHorarios"
              >
                <el-table-column
                  min-width="70"
                  prop="diaNombre"
                  label="Día"
                ></el-table-column>
                <el-table-column
                  prop="horaIngreso"
                  label="Hora Ingreso"
                ></el-table-column>
                <el-table-column
                  prop="horaEgreso"
                  label="Hora Engreso"
                ></el-table-column>

                <el-table-column label="Borrar" width="100">
                  <template slot-scope="props">
                    <el-button
                      type="danger"
                      size="small"
                      @click="eliminar(props.row.id)"
                      round
                    >
                      <i class="el-icon-delete"></i>
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <el-form-item style="text-align: end; margin-right: 30px">
              <el-button
                class="botonNuevomasIcono"
                type="primary"
                round
                style="margin-top: 10px"
                @click="atras"
                texto="Atras"
                >Atras</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </maca-modal>
  </div>
</template>

<style scoped>
</style>

<script>
import moment from "moment";
export default {
  name: "nuevo-usuario",
  components: { moment },
  data() {
    return {
      horariosLoading: false,

      urlTabla: "/centroBeneficiario/obtenerTodos",
      paramsTabla: {},
      actualizarTabla: false,
      bloquearTabla: true,

      filtroNombre: null,

      datosHorarios: [],
      centroBeneficiarioID: null,
      arrayDias: [
        {
          value: "Lunes",
          label: "Lunes",
        },
        {
          value: "Martes",
          label: "Martes",
        },
        {
          value: "Miércoles",
          label: "Miércoles",
        },
        {
          value: "Jueves",
          label: "Jueves",
        },
        {
          value: "Viernes",
          label: "Viernes",
        },
        {
          value: "Sabado",
          label: "Sabado",
        },
        {
          value: "Domingo",
          label: "Domingo",
        },
      ],

      form: {
        dia: [],
        horario: null,
        tipo: null,
      },
      formRules: {
        beneficiario: [
          {
            horario: false,
            message: "Por favor seleccione el horario.",
            trigger: "change",
          },
        ],
      },
      impedirClose: false,
      persona: null,
      idPadre: 0,
      divCalendario: 0,
    };
  },
  methods: {
    buscar() {
      this.paramsTabla.nombre = this.filtroNombre;
      this.actualizarTabla = true;
    },
    atras() {
      this.divCalendario = 0;
    },
    irCalendario(datos) {
      this.obtenerHorarios(datos.id);
      this.centroBeneficiarioID = datos.id;
      this.divCalendario = 1;
    },
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el horario."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post(
        "/centroBeneficiarioHorario/eliminar",
        params
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.obtenerHorarios(this.centroBeneficiarioID);
        this.$message({
          message: "¡Beneficiario borrado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    abrir(idPadre, detalleTipoBeneficiario) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.idPadre = idPadre;
      this.paramsTabla = { centroID: idPadre };
      this.datosHorarios = [];
      this.divCalendario = 0;
      this.centroBeneficiarioID = null;

      this.mostrarTabla = true;
      this.actualizarTabla = true;

      this.form.dia = [];
      this.form.horario = null;

      //mostrar tipo
      this.form.tipo = detalleTipoBeneficiario;
      console.log("tipo", this.form.tipo);
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },

    async agregarTabla() {
      console.log("horario isarray", Array.isArray(this.form.horario));
      let detalleBeneficiarioHorario = [];
      if (
        this.form.dia != null &&
        this.form.dia.length > 0 &&
        this.form.horario != null
      ) {
        this.form.dia.forEach((element) => {
          let horaIngreso;
          let horaEgreso;
          if (Array.isArray(this.form.horario)) {
            horaIngreso = this.form.horario[0];
            horaEgreso = this.form.horario[1];
          } else {
            const dateParsed = moment(this.form.horario, "HH:mm");

            var dateHoursAdded = dateParsed.add(4, "hours");

            horaIngreso = this.form.horario;
            horaEgreso = dateHoursAdded.format("HH:mm");
          }
          detalleBeneficiarioHorario.push({
            id: 0,
            diaNombre: element,
            horaIngreso: horaIngreso,
            horaEgreso: horaEgreso,
            accion: "A",
          });
        });

        // Hacer Post
        let params = {
          centroBeneficiarioID: this.centroBeneficiarioID,
          detalleBeneficiarioHorario: JSON.stringify(
            detalleBeneficiarioHorario
          ),
        };

        let respuestaApi = await this.$maca.api.post(
          "/centroBeneficiarioHorario/crear",
          params
        );
        // Procesar respuesta
        if (respuestaApi.estado == 1) {
          this.form.dia = null;
          this.form.horario = null;
          this.$message({
            message: "¡Horario agregado con éxito!",
            type: "success",
          });

          this.obtenerHorarios(this.centroBeneficiarioID);
        } else {
          this.$message({
            message: respuestaApi.excepcion,
            type: "error",
            showClose: true,
            duration: 0,
          });
        }
        //
      } else {
        this.$message({
          message: "Seleccione un día e ingrese el horario de ingreso y egreso",
          type: "warning",
        });
      }
    },
    async eliminarTabla(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el beneficiario."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post(
        "/centroBeneficiario/eliminar",
        params
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.obtenerHorarios(this.centroBeneficiarioID);
        this.$message({
          message: "¡Beneficiario borrado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },

    async obtenerHorarios(id) {
      this.horariosLoading = true;
      let respuestaApi = await this.$maca.api.get(
        "/centroBeneficiarioHorario/obtenerTodos",
        {
          centroBeneficiarioID: id,
        }
      );
      this.horariosLoading = false;
      if (respuestaApi != null) {
        this.datosHorarios = respuestaApi.datos;
      }
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      console.log(this.form.pais);
      // Hacer Post
      let params = {
        nombre: this.form.provincia,
        paisID: this.idPadre,
      };

      let respuestaApi = await this.$maca.api.post("/provincia/crear", params);
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Provincia creada con éxito!",
          type: "success",
        });

        this.$emit("listo");
        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  },
};
</script>

<style scoped>
.ocultarMostrarCard {
  display: none;
}

.mostrarOcultarCard {
  display: block;
}

@media screen and (max-device-width: 645px) {
  .ocultarMostrarCard {
    display: block;
  }

  .mostrarOcultarCard {
    display: none;
  }
}

.ocultarMostrarItem {
  display: none;
}

.mostrarOcultarItem {
  display: block;
}

@media screen and (max-device-width: 560px) {
  .ocultarMostrarItem {
    display: block;
  }

  .mostrarOcultarItem {
    display: none;
  }
}

.tamañoInputBucar {
  width: 15rem;
}

@media screen and (max-device-width: 385px) {
  .tamañoInputBucar {
    width: 12rem;
  }
}
</style>
