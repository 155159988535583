<template>
  <div>
    <maca-modal
      titulo="Nuevo Transportista"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="mostrarOcultarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="top"
          label-width="130px"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Nombre" prop="nombre">
                <el-input maxlength="20" v-model="form.nombre"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Apellido" prop="apellido">
                <el-input v-model="form.apellido"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Razon Social" prop="razonSocial">
                <el-input maxlength="20" v-model="form.razonSocial"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="CUIT/CUIL" prop="cuitCuil">
                <el-input v-model="form.cuitCuil"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="Domicilio" prop="domicilio">
            <el-input maxlength="30" v-model="form.domicilio"></el-input>
          </el-form-item>
          
          <el-form-item label="Email" prop="email">
            <el-input class="inputEmail" v-model="form.email"></el-input>
          </el-form-item>

          <el-form-item label="Ubicación">
            <el-cascader
              :props="props"
              :show-all-levels="false"
              placeholder="Seleccionar ubicación"
              @change="handleChange"
            ></el-cascader>
          </el-form-item>

          <span v-if="ubicacionIncompleta" style="color: red"
            >Debe seleccionar, al menos, hasta la localidad. De lo contrario, no
            se cargará</span
          >

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Teléfono" prop="telefono">
                <el-input maxlength="15" v-model="form.telefono"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="Tipo de Transportista" prop="tipo">
            <maca-select-box
              v-model="form.tipo"
              :url="urlTiposBeneficiarios"
            ></maca-select-box>
          </el-form-item>

          <el-form-item label="Vive" prop="vive">
            <el-switch
              style="display: block; margin-top: 7px"
              v-model="valueVive"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="SI"
              inactive-text="NO"
            >
            </el-switch>
          </el-form-item>

          <el-form-item>
            <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="ocultarMostrarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="left"
          label-width="130px"
        >
          <el-form-item label="Razon Social" prop="razonSocial">
            <el-input maxlength="20" v-model="form.razonSocial"></el-input>
          </el-form-item>
          <el-form-item label="CUIL/CUIT" prop="CUIT">
            <el-input v-model="form.cuit"></el-input>
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input
              class="inputEmail"
              maxlength="300"
              v-model="form.email"
            ></el-input>
          </el-form-item>

          <el-form-item label="Domicilio" prop="domicilio">
            <el-input maxlength="30" v-model="form.domicilio"></el-input>
          </el-form-item>

          <el-form-item label="Latitud" prop="latitud">
            <el-input maxlength="5" v-model="form.latitud"></el-input>
          </el-form-item>

          <el-form-item label="Longitud" prop="longitud">
            <el-input v-model="form.longitud"></el-input>
          </el-form-item>

          <el-form-item label="Teléfono" prop="telefono">
            <el-input maxlength="15" v-model="form.telefono"></el-input>
          </el-form-item>

          <el-form-item label="Email" prop="email">
            <el-input class="inputEmail" v-model="form.email"></el-input>
          </el-form-item>

          <el-form-item label="Tipo de Beneficiario" prop="tipo">
            <maca-select-box
              v-model="form.tipo"
              :url="urlTiposBeneficiarios"
            ></maca-select-box>
          </el-form-item>

          <el-form-item>
            <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
          </el-form-item>
        </el-form>
      </el-card>
    </maca-modal>
  </div>
</template>

<style scoped>
</style>

<script>
export default {
  name: "nuevo-tipo-centro",
  data() {
    let _self = this;
    return {
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;

          if (level === 0) {
            _self.$maca.api.get("/pais/obtenerTodos", null).then((res) => {
              const nodes = res.datos.map((item) => ({
                value: item.id,
                label: item.nombre,
              }));

              resolve(nodes);
            });
          } else if (level === 1) {
            let params = { paisID: node.data.value , sinPaginar:1 };
            _self.$maca.api
              .get("/provincia/obtenerTodos", params)
              .then((res) => {
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 2) {
            // form.provincia = node.data.value;
            let params = { provinciaID: node.data.value, sinPaginar:1  };
            _self.$maca.api
              .get("/departamento/obtenerTodos", params)
              .then((res) => {
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 3) {
            //  form.departamento = node.data.value;

            let params = { departamentoID: node.data.value, sinPaginar:1  };
            _self.$maca.api
              .get("/localidad/obtenerTodos", params)
              .then((res) => {
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 4) {
            //  form.localidad = node.data.value;
            let params = { localidadID: node.data.value, sinPaginar:1  };
            _self.$maca.api.get("/paraje/obtenerTodos", params).then((res) => {
              console.log(res.datos);
              const nodes = res.datos.map((item) => ({
                value: item.id,
                label: item.nombre,
              }));

              resolve(nodes);
            });
          } else {
            // form.paraje = node.data.value;
            resolve(null);
          }
        },
      },
      form: {
        nombre: null,
        apellido: null,
        razonSocial: null,
        cuitCuil: null,
        domicilio: null,
        pais: null,
        centro: null,
        provincia: null,
        departamento: null,
        localidad: null,
        latitud: null,
        longitud: null,
        tipo: null,
        telefono: null,
        email: null,
        traslado: null,
      },
      impedirClose: false,
      persona: null,
      valueVive: true,
      valueCentro: false,
      urlTiposBeneficiarios: "/tipoBeneficiario/obtenerTodosSelect",
      urlCentro: "/centro/buscar",
      getParams: this.getParamssSelect,

      formRules: {
        domicilio: [
          {
            required: false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        apellido: [
          {
            required: false,
            message: "Por favor introduzca el apellido.",
            trigger: "change",
          },
        ],
        nombre: [
          {
            required: false,
            message: "Por favor introduzca el nombre.",
            trigger: "change",
          },
        ],
        pais: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        provincia: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        cuitCuil: [
          {
            required: true,
            message: "Por favor introduzca el CUIT o CUIL",
            trigger: "change",
          },
        ],
        departamento: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        localidad: [
          {
            required: false,
            message: "Por favor introduzca la localidad.",
            trigger: "change",
          },
        ],

        telefono: [
          {
            required: false,
            message: "Por favor introduzca el teléfono.",
            trigger: "change",
          },
        ],

        email: [
          {
            required: true,
            message: "Por favor introduzca el email.",
            trigger: "change",
          },
        ],
        tipo: [
          {
            required: true,
            message: "Por favor introduzca el tipo.",
            trigger: "change",
          },
        ],
      },

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      value1: true,
      value2: true,
      valueTraslados: true,
      visible: false,
      edad: "",
      yaEligioFecha: false,
      eligioPais: false,

      mostrarMensaje: false,
      urlPais: "/pais/obtenerTodosSelect",
      urlProvincia: "/provincia/obtenerTodosSelect",
      urlDepartamento: "/departamento/obtenerTodosSelect",
      urlLocalidad: "/localidad/obtenerTodosSelect",

      getParamsProvincia: this.getParamsSelectProvincia,
      getParamsDepartamento: this.getParamsSelectDepartamento,
      getParamsLocalidad: this.getParamsSelectLocalidad,

      ubicacionElegida: "",
      localidadID: null,
      departamentoID: null,
      provinciaID: null,
      paisID: null,
      parajeID: null,
      ubicacionActual: true,
      ubicacionIncompleta: false,

      centroID: 0,
    };
  },
  methods: {
    getParamssSelect(query) {
      let params = "";
      params = { nombre: query };
      return params;
    },
    handleChange(node) {
      if (node.length < 4) {
        this.ubicacionIncompleta = true;
        this.ubicacionActual = true;
      } else {
        for (let i = 1; i <= node.length; i++) {
          let indice = i - 1;
          if (i == 1) {
            this.paisID = node[indice];
          } else if (i == 2) {
            this.provinciaID = node[indice];
          } else if (i == 3) {
            this.departamentoID = node[indice];
          } else if (i == 4) {
            this.localidadID = node[indice];
          } else if (i == 5) {
            this.parajeID = node[indice];
          }
        }

        this.ubicacionIncompleta = false;
        this.ubicacionActual = false;
      }
    },
    getPaises() {
      let respuestaApi = this.$maca.api.get("/pais/obtenerTodos", null);
      return respuestaApi;
    },
    getParamsSelectProvincia() {
      let params = "";
      if (this.form.pais != null) {
        params = { paisID: this.form.pais.id };
      }
      return params;
    },
    getParamsSelectDepartamento() {
      let params = "";
      if (this.form.provincia != null) {
        params = { provinciaID: this.form.provincia.id };
      }
      return params;
    },
    getParamsSelectLocalidad() {
      let params = "";
      if (this.form.departamento != null) {
        params = { departamentoID: this.form.departamento.id };
      }
      return params;
    },
    resetearValor(valor) {
      this.mostrarMensaje = true;
      if (valor == 1) {
        this.$refs.refProvincia.recargar();
        this.$refs.refProvinciaMobile.recargar();
        this.form.provincia = null;
        this.form.departamento = null;
        this.form.localidad = null;
        this.eligioPais = true;
      } else if (valor == 2) {
        this.$refs.refDepartamento.recargar();
        this.$refs.refDepartamentoMobile.recargar();
        this.form.departamento = null;
        this.form.localidad = null;
      } else if (valor == 3) {
        this.$refs.refLocalidad.recargar();
        this.$refs.refLocalidadMobile.recargar();
        this.form.localidad = null;
      }
    },
    calcularEdad() {
      let fecha1 = new Date(this.form.fechaN.toString());
      let fecha2 = new Date();
      var edad = fecha2.getFullYear() - fecha1.getFullYear();
      var m = fecha2.getMonth() - fecha1.getMonth();

      if (m < 0 || (m === 0 && fecha2.getDate() < fecha1.getDate())) {
        edad--;
        if (fecha2.getFullYear() == fecha1.getFullYear()) {
          edad++;
        }
      }
      this.edad = edad;
      this.yaEligioFecha = true;
    },
    abrir(centroID) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.centroID = centroID;
      this.persona = null;
    },
    cerrar() {
      this.edad = "";
      this.yaEligioFecha = false;
      this.$refs.modal.cerrar();
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      let detalleTransportistaTipoBeneficiario = [
        { id: 0, tipoBeneficiarioID: this.form.tipo.id, accion: "A" },
      ];
      // Hacer Post
      let params = {
        razonSocial: this.form.razonSocial,
        cuit: this.form.cuitCuil,
        domicilio: this.form.domicilio,
        nombre: this.form.nombre,
        apellido: this.form.apellido,
        telefono: this.form.telefono,
        email: this.form.email,
        fallecido: this.valueVive,
        parajeID: this.parajeID ? this.parajeID : "",
        localidadID: this.localidadID != null ? this.localidadID : "",
        centroID: this.centroID,
        detalleTransportistaTipoBeneficiario: JSON.stringify(
          detalleTransportistaTipoBeneficiario
        ),
      };

      let respuestaApi = await this.$maca.api.post(
        "/transportista/crear",
        params
      );

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Transportista creado con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  },
};
</script>

<style scoped>
.ocultarMostrarCard {
  display: none;
}
.mostrarOcultarCard {
  display: block;
}
@media screen and (max-device-width: 645px) {
  .ocultarMostrarCard {
    display: block;
  }
  .mostrarOcultarCard {
    display: none;
  }
}
</style>