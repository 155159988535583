var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("maca-titulo-pagina", {
        attrs: { icono: "el-icon-office-building", nombrePagina: "Centros" }
      }),
      _c(
        "el-card",
        {
          staticStyle: { margin: "2%" },
          attrs: { "body-style": { padding: "1.5%" } }
        },
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c(
                "div",
                { staticStyle: { "margin-left": "0px" } },
                [
                  _c("el-input", {
                    staticClass: "buscador tamañoInputBucar",
                    attrs: {
                      "prefix-icon": "el-icon-search",
                      placeholder: "Presione ENTER para buscar"
                    },
                    on: { change: _vm.buscar },
                    model: {
                      value: _vm.filtroNombre,
                      callback: function($$v) {
                        _vm.filtroNombre = $$v
                      },
                      expression: "filtroNombre"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "botonmasIcono mostrarOcultarItem",
                  staticStyle: { "margin-right": "0px" },
                  attrs: { icon: "el-icon-plus", type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/centro/nuevoCentro")
                    }
                  }
                },
                [_vm._v("Nuevo Centro")]
              ),
              _c("el-button", {
                staticClass: "botonmasIcono ocultarMostrarItem",
                staticStyle: { "margin-left": "0.2rem" },
                attrs: { icon: "el-icon-plus", type: "primary", circle: "" },
                on: {
                  click: function($event) {
                    return _vm.$refs.modalNuevo.abrir()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "maca-datatable",
                {
                  attrs: {
                    url: _vm.urlTabla,
                    params: _vm.paramsTabla,
                    actualizar: _vm.actualizarTabla,
                    bloquear: _vm.bloquearTabla
                  },
                  on: {
                    "update:actualizar": function($event) {
                      _vm.actualizarTabla = $event
                    },
                    "update:bloquear": function($event) {
                      _vm.bloquearTabla = $event
                    }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "razonSocial", label: "Razon Social" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "cuit", label: "CUIT" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Direccion" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            props.row.detalleParaje != null
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      props.row.detalleParaje.nombre + ", "
                                    )
                                  }
                                })
                              : _vm._e(),
                            props.row.detalleLocalidad != null
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      props.row.detalleLocalidad.nombre + ", "
                                    )
                                  }
                                })
                              : _vm._e(),
                            props.row.detalleDepartamento != null
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      props.row.detalleDepartamento.nombre
                                    )
                                  }
                                })
                              : _vm._e(),
                            props.row.detalleProvincia != null
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      +" - " + props.row.detalleProvincia.nombre
                                    )
                                  }
                                })
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "telefono", label: "Teléfono" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "email", label: "Email" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Traslado" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            props.row.traslado == 1
                              ? _c("div", [_c("span", [_vm._v("SI ")])])
                              : _c("div", [_c("span", [_vm._v("NO ")])])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop:
                        "detalleCentroTipoBeneficiario[0].detalleTipoBeneficiario.nombre",
                      label: "Tipo"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "top-start",
                                  title: "Beneficiarios",
                                  width: "200",
                                  trigger: "hover",
                                  content: "Lista de beneficiarios del centro"
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "Info",
                                      circle: "",
                                      disabled: !_vm.$store.getters.tienePermiso(
                                        "M_USE"
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$refs.modalListaBeneficiario.abrir(
                                          props.row.id,
                                          props.row
                                            .detalleCentroTipoBeneficiario[0]
                                            .detalleTipoBeneficiario
                                        )
                                      }
                                    },
                                    slot: "reference"
                                  },
                                  [_c("i", { staticClass: "el-icon-finished" })]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "top-start",
                                  title: "Beneficiarios",
                                  width: "200",
                                  trigger: "hover",
                                  content: "Agregar beneficiarios al centro"
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "success",
                                      circle: "",
                                      disabled: !_vm.$store.getters.tienePermiso(
                                        "M_USE"
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$refs.modalNuevoBeneficiario.abrir(
                                          props.row
                                        )
                                      }
                                    },
                                    slot: "reference"
                                  },
                                  [_c("i", { staticClass: "el-icon-user" })]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "warning",
                                  circle: "",
                                  disabled: !_vm.$store.getters.tienePermiso(
                                    "M_USE"
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$router.push(
                                      "/centro/modificarCentro/" + props.row.id
                                    )
                                  }
                                }
                              },
                              [_c("i", { staticClass: "el-icon-edit" })]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  circle: "",
                                  disabled: !_vm.$store.getters.tienePermiso(
                                    "B_USE"
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.eliminar(props.row.id)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "el-icon-delete" })]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("modal-nuevo", {
        ref: "modalNuevo",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-modificar", {
        ref: "modalModificar",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-nuevo-transportista", {
        ref: "modalNuevoTransportista",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-nuevo-beneficiario", {
        ref: "modalNuevoBeneficiario",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-listado-beneficiario", {
        ref: "modalListaBeneficiario",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }