<template>
  <div>
    <maca-modal
      titulo="Modificar Centro"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-form
        v-loading="loading"
        :model="form"
        ref="form"
        :rules="formRules"
        label-position="top"
        label-width="130px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Razon Social" prop="razonSocial">
              <el-input maxlength="20" v-model="form.razonSocial"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="CUIT" prop="CUIT">
              <el-input v-model="form.cuit"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="Email" prop="email">
          <el-input
            class="inputEmail"
            maxlength="300"
            v-model="form.email"
          ></el-input>
        </el-form-item>

        <el-form-item label="Domicilio" prop="domicilio">
          <el-input maxlength="30" v-model="form.domicilio"></el-input>
        </el-form-item>

        <el-form-item label="Ubicación actual" v-if="ubicacionActual">
          <strong>
            <span>
              <span v-if="this.form.departamento != null">{{
                this.form.departamento.nombre
              }}</span
              >,
              <span v-if="this.form.localidad != null">{{
                this.form.localidad.nombre
              }}</span>
              ,
              <span v-if="this.form.paraje != null">{{
                this.form.paraje.nombre
              }}</span>
            </span></strong
          >
        </el-form-item>

        <el-form-item label="Ubicación">
          <el-cascader
            :props="props"
            :show-all-levels="false"
            placeholder="Seleccionar ubicación"
            @change="handleChange"
          ></el-cascader>
        </el-form-item>
        <span v-if="ubicacionIncompleta" style="color: red"
          >Debe seleccionar, al menos, hasta la localidad. De lo contrario, se
          mantendrá la ubicación actual</span
        >

        <!--  <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Pais" prop="pais" width="12">
                <maca-select-box
                  placeholder="Seleccionar"
                  v-model="form.pais"
                  :url="urlPais"
                  @change="resetearValor(1)"
                  ref="refPais"
                ></maca-select-box>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                v-if="form.pais"
                label="Provincia"
                prop="provincia"
                width="12"
              >
                <maca-select-box
                  placeholder="Seleccionar"
                  :disabled="!form.pais"
                  v-model="form.provincia"
                  @change="resetearValor(2)"
                  :necesitaParams="true"
                  :getParams="getParamsProvincia"
                  :url="urlProvincia"
                  ref="refProvincia"
                ></maca-select-box>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                v-if="form.provincia"
                label="Departamento"
                prop="departamento"
                width="12"
              >
                <maca-select-box
                  placeholder="Seleccionar"
                  @change="resetearValor(3)"
                  :disabled="!form.provincia"
                  v-model="form.departamento"
                  :necesitaParams="true"
                  :getParams="getParamsDepartamento"
                  :url="urlDepartamento"
                  ref="refDepartamento"
                ></maca-select-box>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                v-if="form.departamento"
                label="Localidad"
                prop="localidad"
                width="12"
              >
                <maca-select-box
                  placeholder="Seleccionar"
                  :disabled="!form.departamento"
                  v-model="form.localidad"
                  :url="urlLocalidad"
                  :necesitaParams="true"
                  :getParams="getParamsLocalidad"
                  ref="refLocalidad"
                ></maca-select-box>
              </el-form-item>
            </el-col>
          </el-row>
          <label
            v-if="form.pais"
            style="font-style: italic; color: grey; font-size: 14px"
          >
            Debe seleccionar hasta la localidad de la ubicación. De lo
            contrario, ésta no se cargará.</label
          > -->

        <div class="contenedor">
          <el-form-item label="Formato de coordenadas" prop="latLongFloat">
            <el-switch
              style="display: block; margin-top: 7px"
              v-model="valueFormatoDecimales"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="Decimales"
              inactive-text="Grados, minutos y segundos"
            >
            </el-switch>

            <label style="color: gray; font-style: italyc"
              >Se almacenará el formato seleccionado</label
            >
          </el-form-item>

          <div v-if="valueFormatoDecimales">
            <el-form-item label="Latitud" prop="latitud">
              <el-input v-model="form.latitud"></el-input>
            </el-form-item>

            <el-form-item label="Longitud" prop="longitud">
              <el-input v-model="form.longitud"></el-input>
            </el-form-item>
          </div>

          <div v-else>
            <el-row :gutter="20">
              <el-col :span="3">
                <el-form-item label="." prop="grados">
                  <label>Latitud</label>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Grados" prop="grados">
                  <el-input v-model="form.gradosLat">
                    <template slot="append">°</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Minutos" prop="minutos">
                  <el-input v-model="form.minutosLat">
                    <template slot="append">'</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Segundos" prop="segundos">
                  <el-input v-model="form.segundosLat">
                    <template slot="append">''</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label="Direccion" prop="direccionCoordenadas">
                  <el-select
                    v-model="form.valueCardinalLat"
                    placeholder="Select"
                  >
                    <el-option
                      v-for="item in puntosCardinales"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="3">
                <el-form-item label="." prop="grados">
                  <label>Longitud</label>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Grados" prop="grados">
                  <el-input v-model="form.gradosLon">
                    <template slot="append">°</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Minutos" prop="minutos">
                  <el-input v-model="form.minutosLon">
                    <template slot="append">'</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Segundos" prop="segundos">
                  <el-input v-model="form.segundosLon">
                    <template slot="append">''</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label="Direccion" prop="direccionCoordenadas">
                  <el-select
                    v-model="form.valueCardinalLon"
                    placeholder="Select"
                  >
                    <el-option
                      v-for="item in puntosCardinales"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Teléfono" prop="telefono">
              <el-input maxlength="15" v-model="form.telefono"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="Tipo de Centro" prop="tipo">
          <maca-select-box
            v-model="form.tipo"
            :url="urlTiposBeneficiarios"
          ></maca-select-box>
        </el-form-item>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Traslados" prop="traslados">
              <el-switch
                style="display: block; margin-top: 7px"
                v-model="valueTraslados"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="SI"
                inactive-text="NO"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Crear Tranportista" prop="creaTranportista">
              <el-switch
                style="display: block; margin-top: 7px"
                v-model="valueCreaTransportista"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="SI"
                inactive-text="NO"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>
<style scoped>
.contenedor {
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 10px;
}
</style>
<script>
export default {
  name: "modificar-usuario",
  data() {
    let _self = this;
    return {
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;

          if (level === 0) {
            _self.$maca.api.get("/pais/obtenerTodos", null).then((res) => {
              console.log(res.datos);
              const nodes = res.datos.map((item) => ({
                value: item.id,
                label: item.nombre,
              }));

              resolve(nodes);
            });
          } else if (level === 1) {
            let params = { paisID: node.data.value, sinPaginar: 1 };
            _self.$maca.api
              .get("/provincia/obtenerTodos", params)
              .then((res) => {
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 2) {
            // form.provincia = node.data.value;
            let params = { provinciaID: node.data.value, sinPaginar: 1 };
            _self.$maca.api
              .get("/departamento/obtenerTodos", params)
              .then((res) => {
                console.log(res.datos);
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 3) {
            //  form.departamento = node.data.value;

            let params = { departamentoID: node.data.value, sinPaginar: 1 };
            _self.$maca.api
              .get("/localidad/obtenerTodos", params)
              .then((res) => {
                console.log(res.datos);
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 4) {
            //  form.localidad = node.data.value;
            let params = { localidadID: node.data.value, sinPaginar: 1 };
            _self.$maca.api.get("/paraje/obtenerTodos", params).then((res) => {
              console.log(res.datos);
              const nodes = res.datos.map((item) => ({
                value: item.id,
                label: item.nombre,
              }));

              resolve(nodes);
            });
          } else {
            // form.paraje = node.data.value;
            resolve(null);
          }
        },
      },

      form: {
        nombre: null,
        apellido: null,
        razonSocial: null,
        cuit: null,
        domicilio: null,
        pais: null,
        provincia: null,
        departamento: null,
        localidad: null,
        latitud: null,
        longitud: null,
        tipo: null,
        telefono: null,
        email: null,
        traslado: null,
        gradosLat: null,
        minutosLat: null,
        segundosLat: null,
        valueCardinalLat: null,
        gradosLon: null,
        minutosLon: null,
        segundosLon: null,
        valueCardinalLon: null,
      },
      impedirClose: false,
      persona: null,
      urlTiposBeneficiarios: "/tipoBeneficiario/obtenerTodosSelect",
      valueFormatoDecimales: true,
      puntosCardinales: [
        {
          value: "N",
          label: "N",
        },
        {
          value: "S",
          label: "S",
        },
        {
          value: "W",
          label: "W",
        },
        {
          value: "E",
          label: "E",
        },
      ],

      formRules: {
        gradosLat: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        latitud: [
          {
            required: !this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        longitud: [
          {
            required: !this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],

        minutosLat: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        segundosLat: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        valueCardinalLat: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        gradosLon: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        minutosLon: [
          {
            required: !this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        segundosLon: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],

        valueCardinalLon: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        dni: [
          {
            required: true,
            message: "Por favor introduzca el DNI.",
            trigger: "change",
          },
        ],

        domicilio: [
          {
            required: true,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        apellido: [
          {
            required: true,
            message: "Por favor introduzca el apellido.",
            trigger: "change",
          },
        ],
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change",
          },
        ],
        pais: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        provincia: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        departamento: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        localidad: [
          {
            required: true,
            message: "Por favor introduzca la localidad.",
            trigger: "change",
          },
        ],

        latitud: [
          {
            required: true,
            message: "Por favor introduzca la latitud.",
            trigger: "change",
          },
        ],
        longitud: [
          {
            required: true,
            message: "Por favor introduzca la longitud.",
            trigger: "change",
          },
        ],
        telefono: [
          {
            required: true,
            message: "Por favor introduzca el teléfono.",
            trigger: "change",
          },
        ],
        traslado: [
          {
            required: true,
            message: "Por favor introduzca el teléfono.",
            trigger: "change",
          },
        ],

        email: [
          {
            required: true,
            message: "Por favor introduzca el email.",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Por favor introduzca un email correcto",
            trigger: ["blur", "change"],
          },
        ],
        tipo: [
          {
            required: true,
            message: "Por favor introduzca el tipo.",
            trigger: "change",
          },
        ],
      },

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      value1: true,
      value2: true,
      valueTraslados: true,
      valueCreaTransportista: false,
      visible: false,
      edad: "",
      yaEligioFecha: false,
      eligioPais: false,

      urlPais: "/pais/obtenerTodosSelect",
      urlProvincia: "/provincia/obtenerTodosSelect",
      urlDepartamento: "/departamento/obtenerTodosSelect",
      urlLocalidad: "/localidad/obtenerTodosSelect",

      getParamsProvincia: this.getParamsSelectProvincia,
      getParamsDepartamento: this.getParamsSelectDepartamento,
      getParamsLocalidad: this.getParamsSelectLocalidad,

      ubicacionElegida: "",
      localidadID: null,
      departamentoID: null,
      provinciaID: null,
      paisID: null,
      parajeID: null,
      ubicacionActual: true,
      ubicacionIncompleta: false,

      loading: false,
    };
  },
  methods: {
    handleChange(node) {
      if (node.length < 4) {
        this.ubicacionIncompleta = true;
        this.ubicacionActual = true;
      } else {
        for (let i = 1; i <= node.length; i++) {
          let indice = i - 1;
          if (i == 1) {
            this.paisID = node[indice];
          } else if (i == 2) {
            this.provinciaID = node[indice];
          } else if (i == 3) {
            this.departamentoID = node[indice];
          } else if (i == 4) {
            this.localidadID = node[indice];
          } else if (i == 5) {
            this.parajeID = node[indice];
          }
        }

        this.ubicacionIncompleta = false;
        this.ubicacionActual = false;
      }
    },

    getParamsSelectProvincia() {
      let params = "";
      if (this.form.pais != null) {
        params = { paisID: this.form.pais.id };
      }
      return params;
    },
    getParamsSelectDepartamento() {
      let params = "";
      if (this.form.provincia != null) {
        params = { provinciaID: this.form.provincia.id };
      }
      return params;
    },
    getParamsSelectLocalidad() {
      let params = "";
      if (this.form.departamento != null) {
        params = { departamentoID: this.form.departamento.id };
      }
      return params;
    },
    resetearValor(valor) {
      if (valor == 1) {
        this.$refs.refProvincia.recargar();
        this.$refs.refProvinciaMobile.recargar();
        this.form.provincia = null;
        this.form.departamento = null;
        this.form.localidad = null;
        this.eligioPais = true;
      } else if (valor == 2) {
        this.$refs.refDepartamento.recargar();
        this.$refs.refDepartamentoMobile.recargar();
        this.form.departamento = null;
        this.form.localidad = null;
      } else if (valor == 3) {
        this.$refs.refLocalidad.recargar();
        this.$refs.refLocalidadMobile.recargar();
        this.form.localidad = null;
      }
    },
    calcularEdad() {
      let fecha1 = new Date(this.form.fechaN.toString());
      let fecha2 = new Date();
      var edad = fecha2.getFullYear() - fecha1.getFullYear();
      var m = fecha2.getMonth() - fecha1.getMonth();

      if (m < 0 || (m === 0 && fecha2.getDate() < fecha1.getDate())) {
        edad--;
        if (fecha2.getFullYear() == fecha1.getFullYear()) {
          edad++;
        }
      }
      this.edad = edad;
      this.yaEligioFecha = true;
    },
    abrir(id) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.id = id;
      this.getDatos();
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async getDatos() {
      this.loading = true;

      let respuestaApi = await this.$maca.api.get("/centro/obtenerDatos", {
        id: this.id,
      });

      this.loading = false;

      if (respuestaApi != null) {
        this.form.nombre = respuestaApi.datos.nombre;
        this.form.apellido = respuestaApi.datos.apellido;
        this.form.email = respuestaApi.datos.email;
        this.form.dni = respuestaApi.datos.dni;
        this.form.domicilio = respuestaApi.datos.domicilio;

        this.form.latitud = respuestaApi.datos.latitud;
        this.form.longitud = respuestaApi.datos.longitud;
        this.form.gradosLat = respuestaApi.datos.latitudDMS.grados;
        this.form.minutosLat = respuestaApi.datos.latitudDMS.minutos;
        this.form.segundosLat = respuestaApi.datos.latitudDMS.segundos;
        this.form.valueCardinalLat = respuestaApi.datos.latitudDMS.direccion;
        this.form.gradosLon = respuestaApi.datos.longitudDMS.grados;
        this.form.minutosLon = respuestaApi.datos.longitudDMS.minutos;
        this.form.segundosLon = respuestaApi.datos.longitudDMS.segundos;
        this.form.valueCardinalLon = respuestaApi.datos.longitudDMS.direccion;

        this.form.telefono = respuestaApi.datos.telefono;
        this.form.cuit = respuestaApi.datos.cuit;
        this.form.razonSocial = respuestaApi.datos.razonSocial;
        this.form.traslado = respuestaApi.datos.traslado;
        if (this.form.traslado == 1) {
          this.valueTraslados = true;
        } else {
          this.valueTraslados = false;
        }
        this.form.paraje = respuestaApi.datos.detalleParaje;

        if (respuestaApi.datos.detalleParaje != null) {
          this.parajeID = this.form.paraje.id;
        }

        this.form.localidad = respuestaApi.datos.detalleLocalidad;
        this.localidadID = this.form.localidad.id;
        this.form.departamento = respuestaApi.datos.detalleDepartamento;
        this.form.provincia = respuestaApi.datos.detalleProvincia;

        this.form.tipo =
          respuestaApi.datos.detalleCentroTipoBeneficiario[0].detalleTipoBeneficiario;
        this.idTipoActual =
          respuestaApi.datos.detalleCentroTipoBeneficiario[0].id;
        this.tipoBeneficiarioIDActual =
          respuestaApi.datos.detalleCentroTipoBeneficiario[0].tipoBeneficiarioID;
      } else {
        this.cerrar();
      }
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      let detalleTipoBeneficiario = [
        {
          id: this.idTipoActual,
          tipoBeneficiarioID: this.tipoBeneficiarioIDActual,
          accion: "B",
        },
        { id: 0, tipoBeneficiarioID: this.form.tipo.id, accion: "A" },
      ];

      let latitudDMS;
      let longitudDMS;
      if (!this.valueFormatoDecimales) {
        this.form.latitud = null;
        this.form.longitud = null;
        latitudDMS = {
          grados: this.form.gradosLat,
          minutos: this.form.minutosLat,
          segundos: this.form.segundosLat,
          direccion: this.form.valueCardinalLat,
        };

        longitudDMS = {
          grados: this.form.gradosLon,
          minutos: this.form.minutosLon,
          segundos: this.form.segundosLon,
          direccion: this.form.valueCardinalLon,
        };
      }

      // Hacer Post
      let params = {
        id: this.id,
        razonSocial: this.form.razonSocial,
        cuit: this.form.cuit,
        domicilio: this.form.domicilio,
        email: this.form.email,
        localidadID: this.localidadID != null ? this.localidadID : "",
        parajeID: this.parajeID != null ? this.parajeID : "",
        latitud: this.form.latitud != null ? parseFloat(this.form.latitud) : "",
        longitud:
          this.form.longitud != null ? parseFloat(this.form.longitud) : "",
        latitudDMS: latitudDMS != null ? JSON.stringify(latitudDMS) : "",
        longitudDMS: longitudDMS != null ? JSON.stringify(longitudDMS) : "",
        telefono: this.form.telefono,
        traslado: this.valueTraslados,
        detalleCentroTipoBeneficiario: JSON.stringify(detalleTipoBeneficiario),
      };

      let respuestaApi = await this.$maca.api.post(
        "/centro/actualizar",
        params
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Centro actualizado con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
    },
  },
};
</script>
