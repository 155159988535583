var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: {
            titulo: "Beneficiarios Asignados",
            impedirClose: _vm.impedirClose,
            mensajeConfirmacion: false
          }
        },
        [
          _c(
            "el-card",
            {
              staticStyle: { margin: "2%" },
              attrs: { "body-style": { padding: "1.5%" } }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.divCalendario == 0,
                      expression: "divCalendario == 0"
                    }
                  ]
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "header" }, slot: "header" },
                    [
                      _c(
                        "div",
                        { staticStyle: { "margin-left": "0px" } },
                        [
                          _c("el-input", {
                            staticClass: "buscador tamañoInputBucar",
                            attrs: {
                              "prefix-icon": "el-icon-search",
                              placeholder: "Presione ENTER para buscar"
                            },
                            model: {
                              value: _vm.filtroNombre,
                              callback: function($$v) {
                                _vm.filtroNombre = $$v
                              },
                              expression: "filtroNombre"
                            }
                          })
                        ],
                        1
                      ),
                      _c("el-button", {
                        staticClass: "botonmasIcono ocultarMostrarItem",
                        staticStyle: { "margin-left": "0.2rem" },
                        attrs: {
                          icon: "el-icon-plus",
                          type: "primary",
                          circle: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.$refs.modalNuevo.abrir()
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "maca-datatable",
                        {
                          attrs: {
                            url: _vm.urlTabla,
                            params: _vm.paramsTabla,
                            actualizar: _vm.actualizarTabla,
                            bloquear: _vm.bloquearTabla
                          },
                          on: {
                            "update:actualizar": function($event) {
                              _vm.actualizarTabla = $event
                            },
                            "update:bloquear": function($event) {
                              _vm.bloquearTabla = $event
                            }
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "Apellido" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(props) {
                                  return [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          props.row.detalleBeneficiario != null
                                            ? props.row.detalleBeneficiario
                                                .detallePersona.apellido
                                            : ""
                                        )
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Nombre" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(props) {
                                  return [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          props.row.detalleBeneficiario != null
                                            ? props.row.detalleBeneficiario
                                                .detallePersona.nombre
                                            : ""
                                        )
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Razon Social" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(props) {
                                  return [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          props.row.detalleBeneficiario != null
                                            ? props.row.detalleBeneficiario
                                                .detallePersona.razonSocial
                                            : ""
                                        )
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "cuitCuil", label: "CUIT/CUIL" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "telefono", label: "Teléfono" }
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Fallecido" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(props) {
                                  return [
                                    props.row.fallecido == 0
                                      ? _c("div", [_c("span", [_vm._v("SI ")])])
                                      : _c("div", [_c("span", [_vm._v("NO ")])])
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { width: "70" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(props) {
                                  return [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top-start",
                                          title: "Horarios",
                                          width: "200",
                                          trigger: "hover",
                                          content:
                                            "Días y horarios del beneficiario en el centro"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              slot: "reference",
                                              type: "Info",
                                              circle: "",
                                              disabled: !_vm.$store.getters.tienePermiso(
                                                "M_USE"
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.irCalendario(
                                                  props.row
                                                )
                                              }
                                            },
                                            slot: "reference"
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-date"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { width: "70" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(props) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "danger",
                                          circle: "",
                                          disabled: !_vm.$store.getters.tienePermiso(
                                            "B_USE"
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.eliminarTabla(
                                              props.row.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete"
                                        })
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.divCalendario == 1,
                      expression: "divCalendario == 1"
                    }
                  ]
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        "label-position": "left",
                        model: _vm.form,
                        "label-width": "75px",
                        rules: _vm.formRules
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { "margin-bottom": "20px" } },
                        [
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 24, offset: 0 } }, [
                                _c(
                                  "h2",
                                  {
                                    staticStyle: {
                                      color: "darkgray",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.form.tipo != null &&
                                            _vm.form.tipo.nombre != null
                                            ? _vm.form.tipo.nombre
                                            : ""
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [
                              _vm._v(
                                " Ingresar dia y horarios de ingreso y egreso de sesión: "
                              )
                            ]
                          ),
                          _c(
                            "el-row",
                            {
                              staticStyle: {
                                "margin-top": "35px",
                                "margin-bottom": "20px"
                              },
                              attrs: { gutter: 25 }
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Día:" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            multiple: "",
                                            placeholder: "Elegir Día"
                                          },
                                          model: {
                                            value: _vm.form.dia,
                                            callback: function($$v) {
                                              _vm.$set(_vm.form, "dia", $$v)
                                            },
                                            expression: "form.dia"
                                          }
                                        },
                                        _vm._l(_vm.arrayDias, function(item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.form.tipo != null &&
                              _vm.form.tipo.codigo == "DIALISIS"
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "Horario:" } },
                                        [
                                          _c("el-time-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "Hora Ingreso",
                                              format: "HH:mm",
                                              "value-format": "HH:mm"
                                            },
                                            model: {
                                              value: _vm.form.horario,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "horario",
                                                  $$v
                                                )
                                              },
                                              expression: "form.horario"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "Horario:" } },
                                        [
                                          _c("el-time-picker", {
                                            attrs: {
                                              "is-range": "",
                                              "range-separator": "A",
                                              selectableRange: "array",
                                              "start-placeholder":
                                                "Hora Ingreso",
                                              "end-placeholder": "Hora Egreso",
                                              format: "HH:mm",
                                              "value-format": "HH:mm"
                                            },
                                            model: {
                                              value: _vm.form.horario,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "horario",
                                                  $$v
                                                )
                                              },
                                              expression: "form.horario"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                              _c(
                                "el-col",
                                { attrs: { span: 5 } },
                                [
                                  _c("el-form-item", { attrs: { label: "" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-right": "0px",
                                          "margin-top": "2px"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "small"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.agregarTabla()
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-plus"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.horariosLoading,
                                  expression: "horariosLoading"
                                }
                              ],
                              ref: "tabla",
                              attrs: { data: _vm.datosHorarios }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "70",
                                  prop: "diaNombre",
                                  label: "Día"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "horaIngreso",
                                  label: "Hora Ingreso"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "horaEgreso",
                                  label: "Hora Engreso"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: { label: "Borrar", width: "100" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(props) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "danger",
                                              size: "small",
                                              round: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.eliminar(
                                                  props.row.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-delete"
                                            })
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            "text-align": "end",
                            "margin-right": "30px"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "botonNuevomasIcono",
                              staticStyle: { "margin-top": "10px" },
                              attrs: {
                                type: "primary",
                                round: "",
                                texto: "Atras"
                              },
                              on: { click: _vm.atras }
                            },
                            [_vm._v("Atras")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }