<template>
  <div>
    <maca-modal
      titulo="Nuevo Beneficiario del centro"
      :impedirClose="impedirClose"
      ref="modal"
      :mensajeConfirmacion="false"
    >
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="mostrarOcultarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="top"
          label-width="130px"
        >
          <el-form-item>
            <el-steps :space="200" :active="active" simple>
              <el-step
                icon="el-icon-user-solid"
                title="Beneficiarios"
              ></el-step>
              <el-step icon="el-icon-map-location" title="Distancia"></el-step>
              <el-step icon="el-icon-date" title="Sesiones"></el-step>
            </el-steps>
          </el-form-item>

          <div v-show="active == 0" class="contenedorStep">
            <el-row :gutter="10">
              <el-col :span="10">
                <el-form-item
                  label="Buscar beneficiario por nombre"
                  prop="nombre"
                >
                  <maca-select-box
                    placeholder="Ingresar nombre de beneficiario"
                    :item-label="['nombreUnificado']"
                    v-model="form.beneficiario"
                    :url="urlCentro"
                    :permitirInput="true"
                    :clearable="true"
                    @change="seleccionSelect"
                    :necesitaParams="true"
                    :getParams="getParams"
                  ></maca-select-box>
                </el-form-item>
              </el-col>

              <el-col :span="7">
                <!-- <el-form-item label="O filtrar por localidad/paraje">
                  <el-cascader
                    :props="props"
                    :show-all-levels="false"
                    ref="cascader"
                    placeholder="Seleccionar ubicación"
                    @change="handleChange"
                    :clearable="true"
                  ></el-cascader>
                </el-form-item>

                <span v-if="ubicacionIncompleta" style="color: red"
                  >Debe seleccionar la localidad o paraje para poder realizar la
                  búsqueda</span
                > -->
              </el-col>
              <el-col :span="2" style="margin-top: 48px">
                <!-- <el-button type="success" circle @click="filtrarBusqueda">
                  <i class="el-icon-search"></i>
                </el-button> -->
              </el-col>
            </el-row>

            <maca-datatable
              :url="urlTabla"
              :params="paramsTabla"
              :actualizar.sync="actualizarTabla"
              :bloquear.sync="bloquearTabla"
              v-if="mostrarTabla"
            >
              <el-table-column
                prop="detallePersona.dni"
                label="DNI"
                :min-width="10"
              >
              </el-table-column>

              <el-table-column label="Apellido y Nombre" :min-width="17">
                <template slot-scope="props">
                  <span
                    v-text="
                      props.row.detallePersona.apellido +
                      ', ' +
                      props.row.detallePersona.nombre
                    "
                  >
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="Direccion" :min-width="20">
                <template slot-scope="props">
                  <span
                    v-if="props.row.detallePersona.detalleParaje != null"
                    v-text="
                      props.row.detallePersona.detalleParaje.nombre + ', '
                    "
                  >
                  </span>
                  <span
                    v-if="props.row.detallePersona.detalleLocalidad != null"
                    v-text="
                      props.row.detallePersona.detalleLocalidad.nombre + ', '
                    "
                  >
                  </span>
                  <span
                    v-if="props.row.detallePersona.detalleDepartamento != null"
                    v-text="props.row.detallePersona.detalleDepartamento.nombre"
                  >
                  </span>
                  <span
                    v-if="props.row.detallePersona.detalleProvincia != null"
                    v-text="
                      +' - ' + props.row.detallePersona.detalleProvincia.nombre
                    "
                  >
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                prop="detallePersona.telefono"
                label="Telefono"
                :min-width="10"
              >
              </el-table-column>
              <el-table-column
                prop="detalleTipoBeneficiario[0].detalleTipoBeneficiario.nombre"
                label="Tipo Beneficio"
                :min-width="10"
              >
              </el-table-column>

              <el-table-column
                prop="detallePersona.fallecido"
                label="Fallecido"
                :min-width="10"
              >
                <template slot-scope="props">
                  <div v-if="props.row.fallecido == 1">
                    <span style="color: red">SI </span>
                  </div>
                  <div v-else>
                    <span>NO </span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                prop="tieneAcompaniante"
                label="Acompañante"
                :min-width="10"
              >
                <template slot-scope="props">
                  <div v-if="props.row.tieneAcompaniante == 1">
                    <span style="color: orange">SI </span>
                  </div>
                  <div v-else>
                    <span>NO </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="70">
                <template slot-scope="props">
                  <el-button type="success" circle @click="next(props.row)">
                    <i class="el-icon-right"></i>
                  </el-button>
                </template>
              </el-table-column>
            </maca-datatable>
          </div>

          <div v-show="active == 1" class="contenedorStep">
            <el-form-item>
              <GmapMap
                id="mapa"
                :zoom="15"
                :center="{
                  lat: parseFloat(this.latitudCentro),
                  lng: parseFloat(this.longitudCentro),
                }"
              >
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in form.markers"
                  :position="m.position"
                  :clickable="true"
                >
                </GmapMarker>
                <DirectionsRenderer travelMode="DRIVING" />
              </GmapMap>
            </el-form-item>
            <div style="margin: auto; margin-left: 0px; margin-top: 15px">
              <strong>Distancia Total: <span id="total"></span></strong>
            </div>
          </div>
          <div v-show="active == 2" class="contenedorStep">
            <el-card :body-style="{ padding: '1.5%' }" style="margin: 2%">
              <el-form
                label-position="left"
                :model="form"
                ref="form"
                label-width="75px"
                :rules="formRules"
              >
                <div style="margin-top: 35px; margin-bottom: 20px">
                  <el-row>
                    <el-col :span="24" :offset="0">
                      <h2 style="color: darkgray; font-weight: bold">
                        {{
                          tipo != null && tipo.nombre != null ? tipo.nombre : ""
                        }}
                      </h2></el-col
                    >
                  </el-row>
                  <span style="font-weight: bold">
                    Ingresar dia y horarios de ingreso y egreso de sesión:
                  </span>
                  <el-row
                    :gutter="25"
                    style="margin-top: 35px; margin-bottom: 20px"
                  >
                    <el-col :span="6">
                      <el-form-item label="Día:">
                        <el-select
                          v-model="form.dia"
                          multiple
                          placeholder="Elegir Día"
                        >
                          <el-option
                            v-for="item in arrayDias"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <!-- PICKER DIALISIS -->
                    <el-col
                      :span="10"
                      v-if="tipo != null && tipo.codigo == 'DIALISIS'"
                    >
                      <el-form-item label="Horario:">
                        <el-time-picker
                          v-model="form.horario"
                          placeholder="Hora Ingreso"
                          format="HH:mm"
                          value-format="HH:mm"
                          style="width: 100%"
                        >
                        </el-time-picker>
                      </el-form-item>
                    </el-col>
                    <!-- PICKER OTROS -->
                    <el-col :span="10" v-else>
                      <el-form-item label="Horario:">
                        <el-time-picker
                          is-range
                          v-model="form.horario"
                          range-separator="A"
                          selectableRange="array"
                          start-placeholder="Hora Ingreso"
                          end-placeholder="Hora Egreso"
                          format="HH:mm"
                          value-format="HH:mm"
                        >
                        </el-time-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5">
                      <el-form-item label="">
                        <div style="margin-right: 0px; margin-top: 2px">
                          <el-button
                            type="primary"
                            size="small"
                            @click="agregarTabla()"
                          >
                            <i class="el-icon-plus"></i>
                          </el-button>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>

                <div>
                  <el-table ref="tabla" :data="datosHorarios">
                    <el-table-column
                      min-width="70"
                      prop="diaNombre"
                      label="Día"
                    ></el-table-column>
                    <el-table-column
                      prop="horaIngreso"
                      label="Hora Ingreso"
                    ></el-table-column>
                    <el-table-column
                      prop="horaEgreso"
                      label="Hora Engreso"
                    ></el-table-column>

                    <el-table-column label="Borrar" width="100">
                      <template slot-scope="props">
                        <el-button
                          type="danger"
                          size="small"
                          @click="eliminar(props.row.id)"
                          round
                        >
                          <i class="el-icon-delete"></i>
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-form>
            </el-card>
          </div>
          <el-form-item v-show="active != 0">
            <div style="display: flex">
              <div style="margin: auto; margin-right: 0px">
                <el-button
                  v-show="active == 1"
                  style="margin-top: 15px"
                  @click="atras"
                  >Atras</el-button
                >
                <el-button
                  v-show="active == 1"
                  style="margin-top: 15px"
                  @click="postApi"
                  >Guardar y continuar
                </el-button>
                <el-button
                  v-show="active == 2"
                  style="margin-top: 15px"
                  @click="cerrar"
                  >Finalizar</el-button
                >
              </div>
            </div>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="ocultarMostrarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="left"
          label-width="130px"
        >
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item
                label="Buscar beneficiario por nombre"
                prop="nombre"
              >
                <maca-select-box
                  placeholder="Ingresar nombre de beneficiario"
                  :item-label="['nombre']"
                  v-model="form.beneficiario"
                  :url="urlCentro"
                  :permitirInput="true"
                  :clearable="true"
                  ref="markupTree"
                  :necesitaParams="true"
                  :getParams="getParams"
                ></maca-select-box>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <!-- <el-form-item label="O filtrar por localidad/paraje">
                <el-cascader
                  :props="props"
                  :show-all-levels="false"
                  placeholder="Seleccionar ubicación"
                  @change="handleChange"
                  @click="filtrarBusqueda"
                ></el-cascader>
              </el-form-item>

              <span v-if="ubicacionIncompleta" style="color: red"
                >Debe seleccionar la localidad o paraje para poder realizar la
                búsqueda</span
              > -->
            </el-col>
            <el-col :span="12">
              <!-- <el-button type="success" circle>
                <i class="el-icon-search"></i>
              </el-button> -->
            </el-col>
          </el-row>

          <maca-datatable
            :url="urlTabla"
            :params="paramsTabla"
            :actualizar.sync="actualizarTabla"
            :bloquear.sync="bloquearTabla"
            v-if="mostrarTabla"
          >
            <el-table-column
              prop="detallePersona.dni"
              label="DNI"
              :min-width="10"
            >
            </el-table-column>
            <el-table-column label="Apellido y Nombre" :min-width="17">
              <template slot-scope="props">
                <span
                  v-text="
                    props.row.detallePersona.apellido +
                    ', ' +
                    props.row.detallePersona.nombre
                  "
                >
                </span>
              </template>
            </el-table-column>
            <el-table-column label="Direccion" :min-width="20">
              <template slot-scope="props">
                <span
                  v-if="props.row.detallePersona.detalleLocalidad != null"
                  v-text="
                    props.row.detallePersona.detalleLocalidad.nombre + ', '
                  "
                >
                </span>
                <span
                  v-if="props.row.detallePersona.detalleDepartamento != null"
                  v-text="props.row.detallePersona.detalleDepartamento.nombre"
                >
                </span>
                <span
                  v-if="props.row.detallePersona.detalleProvincia != null"
                  v-text="
                    +' - ' + props.row.detallePersona.detalleProvincia.nombre
                  "
                >
                </span>
              </template>
            </el-table-column>

            <el-table-column
              prop="detallePersona.telefono"
              label="Telefono"
              :min-width="10"
            >
            </el-table-column>
            <el-table-column
              prop="detalleTipoBeneficiario[0].detalleTipoBeneficiario.nombre"
              label="Tipo Beneficio"
              :min-width="10"
            >
            </el-table-column>

            <el-table-column
              prop="detallePersona.fallecido"
              label="Fallecido"
              :min-width="10"
            >
              <template slot-scope="props">
                <div v-if="props.row.fallecido == 1">
                  <span style="color: red">SI </span>
                </div>
                <div v-else>
                  <span>NO </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="tieneAcompaniante"
              label="Acompañante"
              :min-width="10"
            >
              <template slot-scope="props">
                <div v-if="props.row.tieneAcompaniante == 1">
                  <span style="color: orange">SI </span>
                </div>
                <div v-else>
                  <span>NO </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="70">
              <template slot-scope="props">
                <el-button
                  type="success"
                  circle
                  @click="postApi(props.row.detallePersona)"
                >
                  <i class="el-icon-check"></i>
                </el-button>
              </template>
            </el-table-column>
          </maca-datatable>
        </el-form>
      </el-card>
    </maca-modal>
  </div>
</template>

<style scoped>
</style>

<script>
import DirectionsRenderer from "../../DirectionsRenderer.js";
import moment from "moment";

export default {
  name: "nuevo-usuario",

  components: { DirectionsRenderer, moment },
  data() {
    let _self = this;
    return {
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;

          if (level === 0) {
            _self.$maca.api.get("/pais/obtenerTodos", null).then((res) => {
              const nodes = res.datos.map((item) => ({
                value: item.id,
                label: item.nombre,
              }));

              resolve(nodes);
            });
          } else if (level === 1) {
            let params = { paisID: node.data.value, sinPaginar: 1 };
            _self.$maca.api
              .get("/provincia/obtenerTodos", params)
              .then((res) => {
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 2) {
            // form.provincia = node.data.value;
            let params = { provinciaID: node.data.value, sinPaginar: 1 };
            _self.$maca.api
              .get("/departamento/obtenerTodos", params)
              .then((res) => {
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));
                resolve(nodes);
              });
          } else if (level === 3) {
            let params = { departamentoID: node.data.value, sinPaginar: 1 };
            _self.$maca.api
              .get("/localidad/obtenerTodos", params)
              .then((res) => {
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 4) {
            let params = { localidadID: node.data.value, sinPaginar: 1 };
            _self.$maca.api.get("/paraje/obtenerTodos", params).then((res) => {
              const nodes = res.datos.map((item) => ({
                value: item.id,
                label: item.nombre,
              }));

              resolve(nodes);
            });
          } else {
            // form.paraje = node.data.value;
            resolve(null);
          }
        },
      },

      urlTabla: "/beneficiario/obtenerTodos",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,
      mostrarTabla: false,
      active: 0,
      beneficiariosDeTransportista: [],
      dataBeneficiariosDeTransportista: null,
      distanciaKm: 0,
      idBeneficiario: 0,
      form: {
        markers: [],
        pais: null,
        codigo: null,
        latitud: null,
        longitud: null,
        dia: [],
        horario: null,
      },
      impedirClose: false,
      persona: null,
      levelSeleccionado: 0,

      tipo: null,
      datosHorarios: [],
      centroBeneficiarioID: null,
      arrayDias: [
        {
          value: "Lunes",
          label: "Lunes",
        },
        {
          value: "Martes",
          label: "Martes",
        },
        {
          value: "Miércoles",
          label: "Miércoles",
        },
        {
          value: "Jueves",
          label: "Jueves",
        },
        {
          value: "Viernes",
          label: "Viernes",
        },
        {
          value: "Sabado",
          label: "Sabado",
        },
        {
          value: "Domingo",
          label: "Domingo",
        },
      ],

      formRules: {
        beneficiario: [
          {
            required: true,
            message: "Por favor seleccione el beneficiario.",
            trigger: "change",
          },
        ],
      },

      value1: true,
      value2: true,

      getParams: this.getParamssSelect,
      urlCentro: "/beneficiario/buscar",
      esMail: false,
      esLocalidad: false,

      ubicacionElegida: "",
      localidadID: null,
      departamentoID: null,
      provinciaID: null,
      paisID: null,
      parajeID: null,
      ubicacionActual: true,
      ubicacionIncompleta: false,
      longitudCentro: 0,
      latitudCentro: 0,
    };
  },

  mounted() {
    this.filtrarBusqueda();
  },
  methods: {
    async agregarTabla() {
      let detalleBeneficiarioHorario = [];
      if (
        this.form.dia != null &&
        this.form.dia.length > 0 &&
        this.form.horario != null
      ) {
        this.form.dia.forEach((element) => {
          let horaIngreso;
          let horaEgreso;
          if (Array.isArray(this.form.horario)) {
            horaIngreso = this.form.horario[0];
            horaEgreso = this.form.horario[1];
          } else {
            const dateParsed = moment(this.form.horario, "HH:mm");

            var dateHoursAdded = dateParsed.add(4, "hours");

            horaIngreso = this.form.horario;
            horaEgreso = dateHoursAdded.format("HH:mm");
          }
          detalleBeneficiarioHorario.push({
            id: 0,
            diaNombre: element,
            horaIngreso: horaIngreso,
            horaEgreso: horaEgreso,
            accion: "A",
          });
        });
        // Hacer Post
        let params = {
          centroBeneficiarioID: this.centroBeneficiarioID,
          detalleBeneficiarioHorario: JSON.stringify(
            detalleBeneficiarioHorario
          ),
        };

        let respuestaApi = await this.$maca.api.post(
          "/centroBeneficiarioHorario/crear",
          params
        );
        // Procesar respuesta
        if (respuestaApi.estado == 1) {
          this.form.dia = null;
          this.form.horario = null;
          this.$message({
            message: "¡Horario agregado con éxito!",
            type: "success",
          });

          this.obtenerHorarios(this.centroBeneficiarioID);
        } else {
          this.$message({
            message: respuestaApi.excepcion,
            type: "error",
            showClose: true,
            duration: 0,
          });
        }
        //
      } else {
        this.$message({
          message: "Seleccione un día e ingrese el horario de ingreso y egreso",
          type: "warning",
        });
      }
    },
    async obtenerHorarios(id) {
      let respuestaApi = await this.$maca.api.get(
        "/centroBeneficiarioHorario/obtenerTodos",
        {
          centroBeneficiarioID: id,
        }
      );
      if (respuestaApi != null) {
        this.datosHorarios = respuestaApi.datos;
      }
    },
    atras() {
      this.active--;
    },
    async next(datosBeneficiario) {
      // console.log("datos beneficiario");
      // console.log(datosBeneficiario);

      if (datosBeneficiario.detalleTipoBeneficiario != null) {
        this.idBeneficiario =
          datosBeneficiario.detalleTipoBeneficiario[0].beneficiarioID;

        const resultCalcularRuta = await this.calcularRuta(datosBeneficiario);

        if (resultCalcularRuta) {
          this.active++;
        }
      } else {
        this.$confirm(
          "¡Datos incompletos de beneficiario!",
          "Confirmar redirección",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            this.$router.push(
              `/beneficiario/modificarBeneficiario/${datosBeneficiario.id}`
            );
          })
          .catch(() => {});
      }
    },
    async calcularRuta(datosBeneficiario) {
      this.form.markers = [];
      var origen =
        datosBeneficiario.detallePersona.latitud +
        ", " +
        datosBeneficiario.detallePersona.longitud;
      var destino = this.latitudCentro + ", " + this.longitudCentro;

      const positionBeneficiario = {
        full_name: datosBeneficiario.detallePersona.apellidoNombre,
        lat: parseFloat(datosBeneficiario.detallePersona.latitud),
        lng: parseFloat(datosBeneficiario.detallePersona.longitud),
      };
      const positionCentro = {
        lat: parseFloat(this.latitudCentro),
        lng: parseFloat(this.longitudCentro),
      };
      this.form.markers.push({ position: positionBeneficiario });
      this.form.markers.push({ position: positionCentro });

      return await this.armadoDeRutas(origen, destino);
    },
    async armadoDeRutas(origen, destino) {
      const map = new google.maps.Map(document.getElementById("mapa"));
      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer({
        draggable: false,
        map,
        // panel: document.getElementById("right-panel"), INDICACIONES DE RUTA
      });
      directionsRenderer.addListener("directions_changed", () => {
        this.computeTotalDistance(directionsRenderer.getDirections());
      });
      return await this.displayRoute(
        origen,
        destino,
        directionsService,
        directionsRenderer
      );
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async displayRoute(origin, destination, service, display) {
      let resultado = false;
      const resultServiceRoute = await service.route(
        {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
          avoidTolls: true,
        },
        (result, status) => {
          if (status === "OK" && result) {
            display.setDirections(result);
            this.rutaCalculada = true;
            resultado = true;
          } else {
            this.$message({
              message: `No se pudo dibujar la ruta, por favor verifique los datos del centro y del beneficiario. Detalle: ${status}`,
              type: "error",
            });

            resultado = false;
          }
        }
      );
      console.log(resultServiceRoute);
      return resultado;
    },
    computeTotalDistance(result) {
      let total = 0;
      const myroute = result.routes[0];

      if (!myroute) {
        return;
      }

      for (let i = 0; i < myroute.legs.length; i++) {
        total += myroute.legs[i].distance.value;
      }
      total = total / 1000;
      if (total > 5) total = parseInt(total);

      this.distanciaKm = total;
      document.getElementById("total").innerHTML = total + " km";
    },
    seleccionSelect() {
      this.paramsTabla = { dni: this.form.beneficiario.dni };

      this.mostrarTabla = true;
      this.actualizarTabla = true;
    },
    filtrarBusqueda() {
      if (this.esLocalidad) {
        this.paramsTabla = { localidadID: this.localidadID };
      } else {
        this.paramsTabla = { parajeID: this.parajeID };
      }
      this.mostrarTabla = true;
      this.actualizarTabla = true;
    },

    handleChange(node) {
      if (node.length < 4) {
        this.ubicacionIncompleta = true;
        this.ubicacionActual = true;
      } else {
        if (node.length == 4) {
          this.esLocalidad = true;
        } else this.esLocalidad = false;

        for (let i = 1; i <= node.length; i++) {
          let indice = i - 1;
          if (i == 1) {
            this.paisID = node[indice];
          } else if (i == 2) {
            this.provinciaID = node[indice];
          } else if (i == 3) {
            this.departamentoID = node[indice];
          } else if (i == 4) {
            this.localidadID = node[indice];
          } else if (i == 5) {
            this.parajeID = node[indice];
          }
        }

        this.ubicacionIncompleta = false;
        this.ubicacionActual = false;
      }
    },
    getParamssSelect(query) {
      let params = "";
      params = { nombre: query };
      return params;
    },
    abrir(datosCentro) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.id = datosCentro.id;
      this.tipo = null;
      this.tipo =
        datosCentro.detalleCentroTipoBeneficiario[0].detalleTipoBeneficiario;
      this.centroBeneficiarioID = null;
      this.latitudCentro = datosCentro.latitud;
      this.longitudCentro = datosCentro.longitud;

      this.active = 0;
      this.beneficiariosDeTransportista = [];
      this.dataBeneficiariosDeTransportista = null;
      this.distanciaKm = 0;
      this.idBeneficiario = 0;
      this.form.markers = [];
      this.form.dia = [];
      this.form.horario = null;

      this.impedirClose = false;
      this.persona = null;

      this.levelSeleccionado = 0;

      this.datosHorarios = [];
      this.centroBeneficiarioID = null;
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      let detalleBeneficiario = [
        {
          id: 0,
          beneficiarioID: this.idBeneficiario,
          distanciaKm: this.distanciaKm,
          accion: "A",
        },
      ];
      // Hacer Post
      let params = {
        centroID: this.id,
        detalleBeneficiario: JSON.stringify(detalleBeneficiario),
      };

      let respuestaApi = await this.$maca.api.post(
        "/centroBeneficiario/crear",
        params
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Beneficiario agregado con éxito!",
          type: "success",
        });

        this.centroBeneficiarioID = respuestaApi.datos.id[0];
        this.active++;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return true;
    },
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el horario."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post(
        "/centroBeneficiarioHorario/eliminar",
        params
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.obtenerHorarios(this.centroBeneficiarioID);
        this.$message({
          message: "¡Beneficiario borrado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  },
};

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}
</script>

<style scoped>
.ocultarMostrarCard {
  display: none;
}

.mostrarOcultarCard {
  display: block;
}

@media screen and (max-device-width: 645px) {
  .ocultarMostrarCard {
    display: block;
  }

  .mostrarOcultarCard {
    display: none;
  }
}

.contenedorStep {
  background-color: #f5f7fa;
  padding: 15px;
  border-radius: 10px;
}

.vue-map-container {
  height: 450px;
  width: 100%;
}
</style>
